import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

import {Dropdown, Menu, Button, Modal, message, PageHeader, Tag} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
  EyeOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusCircleOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import UserEditModal from './UserEditModal'
import UserAddModal from './UserAddModal'
import UserServiceViewModal from './UserServiceViewModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolPermission from '../../tools/ToolPermission'

export default function UserViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const userPermissionsData = useSelector(state => state.userPermissions.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [userEditModal, setUserEditModal] = useState(null)
  const [userAddModal, setUserAddModal] = useState(null)
  const [userServiceViewModal, setUserServiceViewModal] = useState(null)

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setUserEditModal({
                      visible: true,
                      userId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/user/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                  case 'user-service': {
                    setUserServiceViewModal({
                      visible: true,
                      userId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯用戶')}
                  </Menu.Item>
                )}
                {ToolPermission.hasPermission(userPermissionsData, 'user', 'delete') &&(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除用戶')}
                  </Menu.Item>
                )}
                {record.type === 'SERVICE_ADMIN_USER' && (
                  <Menu.Item key="user-service">
                    <EyeOutlined/>
                    {I18n.t('用戶服務')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'type',
      title: I18n.t('類型'),
      sorter:true,
      render: (value, record) => {
        if(value){
          switch (value) {
            case 'SERVICE_ADMIN_USER':{
              return '服務管理員'
            }
            case 'SUPER_ADMIN_USER':{
              return '超級管理員'
            }
          }
        }
      },
    },
    {
      key: 'email',
      sorter:true,
      title: I18n.t('郵箱')
    },
    {
      key: 'name',
      sorter:true,
      title: I18n.t('名稱')
    },
    {
      key: 'fullName',
      sorter:true,
      title: I18n.t('全名')
    },
    {
      key: 'phone',
      sorter:true,
      title: I18n.t('電話')
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      title: I18n.t('启用'),
      sorter:true,
    },
    {
      template: 'datetime',
      sorter:true,
      key: 'createdAt',
      title: I18n.t('createdAt'),
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'type',
      title: '類型',
      defaultValue:'SERVICE_ADMIN_USER',
      options: ToolPermission.hasPermission(userPermissionsData, 'user', 'post') ? [
        {
          label: `服務管理員`,
          value: 'SERVICE_ADMIN_USER'
        },
        {
          label: `超級管理員`,
          value: 'SUPER_ADMIN_USER'
        }
      ] : [{
        label: `服務管理員`,
        value: 'SERVICE_ADMIN_USER'
      }]
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      type: values.type
    }
  }

  const loadDataSource = async (body) => {
    const userRes = await ToolRequest.request('GET', '/user', {
      ...body,
    })
    console.dir(userRes)
    return userRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('用戶列表')}
      extra={[
        ToolPermission.hasPermission(userPermissionsData, 'user', 'post') && (
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setUserAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        searchColumns={searchColumns}
      />
      {userEditModal && <UserEditModal
        key={userEditModal.key}
        visible={userEditModal.visible}
        userId={userEditModal.userId}
        onOk={() => {
          setUserEditModal({
            ...userEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setUserEditModal({
            ...userEditModal,
            visible: false,
          })
        }}
      />}
      {userAddModal && <UserAddModal
        key={userAddModal.key}
        visible={userAddModal.visible}
        onOk={() => {
          setUserAddModal({
            ...userAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setUserAddModal({
            ...userAddModal,
            visible: false,
          })
        }}
      />}
      {userServiceViewModal && <UserServiceViewModal
        key={userServiceViewModal.key}
        visible={userServiceViewModal.visible}
        userId={userServiceViewModal.userId}
        onOk={() => {
          setUserServiceViewModal({
            ...userServiceViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setUserServiceViewModal({
            ...userServiceViewModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};
