import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n, setLocale } from 'react-redux-i18n';

import { connect, useDispatch } from 'react-redux';

import { Form, Input, Button, Typography, Spin, message } from 'antd';

import { updateUser } from '../../reducers/userSlice';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolUser from '../../tools/ToolUser';

import '../../less/LoginScreen.less';
import { updateUserPermissions } from '../../reducers/userPermissionsSlice'

const formLayout = {
  labelCol: {
    sm: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
}

const formTailLayout = {
  wrapperCol: {
    sm: {
      offset: 6,
      span: 12,
    },
  },
};

const { Title } = Typography;

export default function LoginScreen(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sendEmailLabel, setSendEmailLabel] = useState('發送驗證碼郵件');
  const [sendEmailDisabled, setSendEmailDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const onFinish = async (values) => {
    try {
      setLoading(true)
      await ToolRequest.request('POST', '/auth/password/verify', {
        username: values.username,
        password: values.password,
        code: values.code,
      })
      message.success(I18n.t('登錄成功'))

      const search = history.location.search.substr(1).split('&')
      const next = search.find(tmp => tmp.match(/^next=/)) || null
      const nextPath = next ? decodeURIComponent(next.substr(5)) : '/';

      const userRes = await ToolRequest.request('GET', '/user/me')
      const user = userRes.data
      console.dir(user)
      ToolUser.updateLocale(user.locale)
      dispatch(setLocale(user.locale))
      dispatch(updateUser(user))
      const userPermissionsRes = await ToolRequest.request('GET', '/user-permission')
      console.dir(userPermissionsRes.data)
      dispatch(updateUserPermissions(userPermissionsRes.data));
      history.replace(!nextPath.match(/^\/login/) ? nextPath : '/');
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          case 'INVALID_USER_NAME': {
            return message.error(I18n.t('用戶不存在'))
          }
          case 'INCORRECT_PASSWORD': {
            return message.error(I18n.t('密碼錯誤'))
          }
          case 'INVALID_CODE': {
            return message.error(I18n.t('此驗證碼不存在或已過期，請重新發送驗證碼郵件'))
          }
          case 'USER_DISABLED': {
            return message.error(I18n.t('此用戶已禁用'))
          }
          case 'INSUFFICIENT_PRIVILEGE': {
            history.replace(`/login?next=${encodeURIComponent(history.location.pathname)}`)
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }
      message.error(I18n.t('errorMessageUnknownError'));

    }
  };

  return (
    <Spin spinning={loading}>
      <div id="loginContainer">
        <div>
          <Title level={2} className="title">會員系統管理后臺</Title>

          <Form
              form={form}
            {...formLayout}
            onFinish={onFinish}
          >
            <Form.Item
              label="郵箱"
              name="username"
              rules={[
                {
                  required: true,
                  type: 'email'
                },
              ]}
            >
              <Input
                placeholder={I18n.t('用戶名')}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('密碼')}
              name="password"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('密碼'),
                  }),
                },
              ]}
            >
              <Input.Password
                placeholder={I18n.t('密碼')}
              />
            </Form.Item>

            <Form.Item label="驗證碼">
              <Input.Group compact>
                <Form.Item
                    noStyle
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: '請輸入六位驗證碼',
                      },
                    ]}
                >
                  <Input
                      style={{
                        width: 'calc(100% - 130px)',
                      }}
                      placeholder={I18n.t('驗證碼')}
                  />
                </Form.Item>
                <Form.Item
                    noStyle
                >
                  <Button disabled={sendEmailDisabled} type="default" onClick={async (event) => {
                    let countdownTimer;
                    try {
                      const email = form.getFieldValue('username')
                      let errorMessage = ''
                      await form.validateFields(['username']).catch(errorInfo => {
                        const error = errorInfo.errorFields.length ? errorInfo.errorFields[0] : null
                        if (error) {
                          errorMessage = error.errors ? error.errors[0] : '未知錯誤'
                          message.error(errorMessage)
                        }
                      });
                      if (!errorMessage) {
                        await ToolRequest.request('POST', '/auth/email', {email})
                        setSendEmailDisabled(true)
                        const startCountdown = () => {
                          const interval = 1000; // 1秒
                          let seconds = countdown;
                          countdownTimer = setInterval(() => {
                            seconds -= 1;
                            setCountdown(seconds);
                            setSendEmailLabel(seconds.toString())
                            if (seconds === 0) {
                              clearInterval(countdownTimer);
                              setCountdown(60)
                              setSendEmailDisabled(false)
                              setSendEmailLabel('發送驗證碼郵件')
                            }
                          }, interval);
                        };
                        startCountdown()
                      }
                    } catch (e) {
                      clearInterval(countdownTimer);
                      if (e?.response?.data) {
                        switch (e.response.data) {
                          case 'INVALID_EMAIL': {
                            return message.error(I18n.t('郵箱錯誤或不存在'))
                          }
                          default:
                            return message.error(I18n.t('errorMessageUnknownError'));
                        }
                      }
                      message.error(I18n.t('errorMessageUnknownError'));
                    }
                  }}>{sendEmailLabel}</Button>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="default" style={{backgroundColor:"#bae7ff"}} htmlType="submit" block>
                {I18n.t('登錄')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
