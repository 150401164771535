import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import * as ToolTable from '../../tools/ToolTable'
import queryString from 'querystringify'

import {
  Button,
  Modal,
  message,
  PageHeader,
  Spin,
  Input, Tag,
} from 'antd'
import {
  DownloadOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusCircleOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import FamilyServiceBatchAddModal from './FamilyServiceBatchAddModal'

const { TextArea } = Input

export default function FamilyServiceViewModal (props) {
  const [loaded, setLoaded] = useState(false)
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const {serviceId,visible, onOk, onCancel} = props
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState(null)
  const [service, setService] = useState(null)
  const [familyServiceBatchAddModal, setFamilyServiceBatchAddModal] = useState(null)

  const columns = [
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'familyNumber',
      sorter:true,
      title: I18n.t('familyNumber')
    },
    {
      key: 'applicantPhone',
      title: I18n.t('聯繫電話'),
      sorter:true,
    },
    {
      key: 'regionName',
      title: I18n.t('地區'),
    },
    {
      key: 'status',
      title: I18n.t('服務状态'),
      render: (value, record) => {
        return {
          ONGOING: <Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag>,
          WAITING: <Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag>,
          ENDED: <Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag>,
          TERMINATED: <Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag>,
        }[value];
      },
    },
    {
      template: 'datetime',
      key: 'beginAt',
      title: I18n.t('開始時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'endAt',
      title: I18n.t('结束時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'terminatedAt',
      title: I18n.t('中止時間'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      sorter:true,
      title: I18n.t('createdAt'),
    },
  ]

  const searchQueryParams = values => {
    const beginAt = ToolTable.formatDatePicker(values.beginAt)
    const endAt = values.endAt ? ToolTable.formatDatePicker(values.endAt) : null
    return {
      search: values.search ? `%${values.search}%` : undefined,
      status:values.status,
      beginAt,
      endAt,
    }
  }

  const searchColumns = [
    {
      template: 'select',
      key: 'status',
      title: '服務狀態',
      defaultValue:'ONGOING',
      options: [
        {
          label: <Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag>,
          value: 'ONGOING'
        },
        {
          label: <Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag>,
          value: 'WAITING'
        },
        {
          label: <Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag>,
          value: 'ENDED'
        },
        {
          label: <Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag>,
          value: 'TERMINATED'
        },
      ]
    },
    {
      template: 'datePicker',
      key: 'beginAt',
      placeholder: '服務開始日期'
    },
    {
      template: 'datePicker',
      key: 'endAt',
      placeholder: '服務結束日期'
    },
  ]

  useEffect(() => {
    (async () => {
      const serviceRes = await ToolRequest.request('GET', `/service/${serviceId}`)
      setService(serviceRes.data)
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    let options = {
      includeFamily:true,
      serviceId,
      ...body,
    }
    console.dir(options)
    const familyServiceRes = await ToolRequest.request('GET', `/family-service`, options)
    console.dir(familyServiceRes)
    setTableParams(options)
    return familyServiceRes
  }

  return (
    <Modal
      title={I18n.t('')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <PageHeader
            title={`${I18n.t('家庭服務列表')} (${service.name[userData.locale]})`}
            extra={[
              (
                <Button type="primary" onClick={()=>{
                  setFamilyServiceBatchAddModal({
                    visible: true,
                    serviceId,
                    key: Math.random().toString(),
                  })
                }}>
                  批量添加家庭服務
                </Button>
              ),
              (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    const params = queryString.stringify({
                      isExport:true,
                      ...tableParams
                    })
                    console.dir(params)
                    let hiddenElement = document.createElement('a')
                    hiddenElement.href = `${metadata.cloudHost}family-service?${params}`
                    hiddenElement.target = '_blank'
                    hiddenElement.click()
                  }}
                  icon={<DownloadOutlined />}
                >
                  {I18n.t('導出Excel')}
                </Button>
              ),
            ]}
          >
            <SuperTable
              showSearchInput
              searchQueryParams={searchQueryParams}
              searchColumns={searchColumns}
              tableKey={tableKey}
              onTableKeyChange={key => setTableKey(key)}
              loadDataSource={loadDataSource}
              columns={columns}
            />
            {familyServiceBatchAddModal && <FamilyServiceBatchAddModal
              key={setTableParams.key}
              visible={familyServiceBatchAddModal.visible}
              serviceId={familyServiceBatchAddModal.serviceId}
              onOk={() => {
                setFamilyServiceBatchAddModal({
                  ...familyServiceBatchAddModal,
                  visible: false,
                })
                setTableKey(Math.random().toString())
              }}
              onCancel={() => {
                setFamilyServiceBatchAddModal({
                  ...familyServiceBatchAddModal,
                  visible: false,
                })
              }}
            />}
          </PageHeader>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
