import moment from 'moment'

export const ranges = {
  '今天': [moment(), moment()],
  '昨天': [moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'day')],
  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()],
}

export const formatRangePicker = (rangePickers = '')=>{
  let startTime = moment('2023-1-1','YYYY-MM-DD').startOf('days').toISOString()
  let endTime = moment().endOf('days').toISOString()
  if(rangePickers && rangePickers.length){
    startTime = moment(rangePickers[0]).startOf('days').toISOString()
    if(rangePickers.length >= 2){
      endTime = moment(rangePickers[1]).endOf('days').toISOString()
    }
  }
  return {startTime,endTime}
}

export const formatDatePicker = (datePicker = '')=>{
  let startTime = moment('2000-1-1','YYYY-MM-DD').startOf('days').toISOString()
  if(datePicker){
    startTime = moment(datePicker).startOf('days').toISOString()
  }
  return startTime
}